import { template as template_338a520e94c4475791e8939da354c389 } from "@ember/template-compiler";
const FKControlMenuContainer = template_338a520e94c4475791e8939da354c389(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
